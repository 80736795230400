// @flow
// TODO: Decide whether you want to use the imports of the individual SVGs, or whether you can instead just include the paths as per the commented example
// TODO: Move this to the ./src/theme directory once all cleaned up

// export const icons = {
//   BUBBLE:
//     'M512 64c282.77 0 512 186.25 512 416 0 229.752-229.23 416-512 416-27.156 0-53.81-1.734-79.824-5.044-109.978 109.978-241.25 129.7-368.176 132.596v-26.916c68.536-33.578 128-94.74 128-164.636 0-9.754-0.758-19.33-2.164-28.696-115.796-76.264-189.836-192.754-189.836-323.304 0-229.75 229.23-416 512-416z',
//   ENLARGE2:
//     'M1024 0v416l-160-160-192 192-96-96 192-192-160-160zM448 672l-192 192 160 160h-416v-416l160 160 192-192z',
// };

import podcastApple from './podcast-apple.svg';
import podcastGoogle from './podcast-google.svg';
import podcastOvercast from './podcast-overcast.svg';
import podcastRss from './podcast-rss.svg';
import podcastSpotify from './podcast-spotify.svg';
import podcastStitcher from './podcast-stitcher.svg';

export const ICONS = {
  podcastApple: podcastApple,
  podcastGoogle: podcastGoogle,
  podcastOvercast: podcastOvercast,
  podcastRss: podcastRss,
  podcastSpotify: podcastSpotify,
  podcastStitcher: podcastStitcher,
};
