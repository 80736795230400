// @flow
import React from 'react';
import styled from 'styled-components';

import Col from '../../../components/Col';
import FormSubscribe from '../../../components/FormSubscribe';
import Icon from '../../../components/Icon';
import Layout from '../../../components/Layout';
import Link from '../../../components/Link';
import Markdown from '../../../components/Markdown';
import Row from '../../../components/Row';
import Section from '../../../components/Section';
import SectionSingleColumn from '../../../components/SectionSingleColumn';
import TextSmallCaps from '../../../components/TextSmallCaps';
import strings from '../../../config/strings.json';
import {colors, mediaQueries} from '../../../theme';
import dimensions from '../../../theme/dimensions';
import {LocationType} from '../../../types';
import {rhythm} from '../../../utils/typography';

const StyledPlatformCard = styled(Col)`
  flex-basis: 50%;
  ${mediaQueries.MIN_LG} {
    flex-basis: 33.3333333333%;
  }

  &:first-of-type {
    margin-top: ${dimensions.spacing.small};
    ${mediaQueries.MIN_MD} {
      margin-top: ${dimensions.spacing.default};
    }
  }

  svg {
    width: ${dimensions.icon.large};
    height: ${dimensions.icon.large};
    margin-bottom: ${rhythm(1 / 3)};
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    margin: 0 ${rhythm(1 / 3)} ${rhythm(1 / 3)} 0;
    padding: 0 0.25rem;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    border: 1px solid ${colors.black20};
    border-radius: ${dimensions.borderRadius};

    &:hover,
    &:focus {
      text-decoration: none;
      color: ${colors.blackSoft};
      border-color: ${colors.blackSoft};

      small {
        color: ${colors.blackSoft};
      }
    }
  }
`;

class PodcastSubscribe extends React.Component {
  render() {
    const {location} = this.props;
    const {pathname: path} = location;
    const {pageMeta, header, content} =
      strings.pages['healthredesigned/subscribe'];

    const subscribeOptions = [
      {
        icon: 'podcastApple',
        title: 'Apple Podcasts',
        url: 'https://itunes.apple.com/podcast/healthredesigned/id1315059059',
      },
      {
        icon: 'podcastGoogle',
        title: 'Google Podcasts',
        url: 'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS9WNUd0X0hKRQ',
      },
      {
        icon: 'podcastOvercast',
        title: 'Overcast',
        url: 'https://overcast.fm/itunes1315059059/healthredesigned',
      },
      {
        icon: 'podcastSpotify',
        title: 'Spotify',
        url: 'https://open.spotify.com/show/0IicERR8i0muceDLwKljjJ?si=3Bc5qUqqRrexTkZlBq_OSQ',
      },
      {
        icon: 'podcastStitcher',
        title: 'Stitcher',
        url: 'http://stitcher.com/s?fid=158532&refid=stpr',
      },
      {
        icon: 'podcastRss',
        title: 'RSS',
        url: strings.pages.healthredesigned.pageMeta.rss.url, // NB: This is retrieved from the sibling object
      },
    ];

    return (
      <Layout path={path} pageMeta={{strings: pageMeta}}>
        <SectionSingleColumn header={header}>
          <Markdown content={content} />
          <Row noGutter style={{alignItems: 'flex-end'}}>
            {subscribeOptions.map((option, index) => {
              return (
                <StyledPlatformCard key={index} data-testid={option.title}>
                  <Link to={option.url}>
                    <Icon icon={option.icon} />
                    <TextSmallCaps>{option.title}</TextSmallCaps>
                  </Link>
                </StyledPlatformCard>
              );
            })}
          </Row>
          <Section theme="expanded">
            <h4>Subscribe to our Newsletter</h4>
            <p>
              We send out intermittent updates a few times per year, including
              when a new <em>HealthRedesigned</em> season launches.
            </p>
            <FormSubscribe
              inputList={{
                name: 'group[24169][1]',
                id: 'mce-group[24169]-24169-0',
              }}
            />
          </Section>
        </SectionSingleColumn>
      </Layout>
    );
  }
}

PodcastSubscribe.propTypes = {
  location: LocationType,
};

export default PodcastSubscribe;
