// @flow
import PropTypes from 'prop-types';
import React from 'react';

import {ICONS} from '../assets/icons';

class Icon extends React.Component {
  render() {
    const {icon} = this.props;
    if (ICONS[icon]) {
      return ICONS[icon]();
    }
    return null; // You could also return a question mark icon here, if you wanted to be more helpful
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
